import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { environment } from '@environment';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { routes } from './app.routes';
import { appInitializer } from './core/providers/app-initializer.provider';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Angulartics2Module } from 'angulartics2';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthModule } from './core/auth/auth.module';
import { ErrorsModule } from './core/errors/errors.module';
import { provideTransloco } from '@jsverse/transloco';
import { translocoConfig } from '@app/transloco.config';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { TranslationDomainService } from './domain/translation-domain.service';
import { TranslationService } from '@ess-front/shared';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from '@app/core/date/custom-date.adapter';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { CustomDatepickerIntl } from '@app/core/date/custom-date-picker-intl.service';
import { SupportedLanguage } from '@app/utils/constants/ess-date-config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
      Angulartics2Module.forRoot(),
      MatSnackBarModule,
      MatNativeDateModule,
      AuthModule,
      ErrorsModule,
    ),
    { provide: 'googleTagManagerId', useValue: environment.gtmApiKey },
    { provide: 'env', useValue: environment },
    { provide: MAT_DATE_LOCALE, useValue: SupportedLanguage.En },
    { provide: MatDatepickerIntl, useClass: CustomDatepickerIntl },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    provideTransloco(translocoConfig),
    provideTranslocoMessageformat(),
    appInitializer,
    {
      provide: TranslationService,
      useClass: TranslationDomainService,
    },
  ],
};
